<template>
<!-- Main content -->
  <section class="content">
    <div class="btn-box">
      <el-button type="primary" size="mini" @click="add">添加分类</el-button>
      <el-button type="primary" size="mini" @click="more_add">批量添加分类</el-button>
    </div>
    <el-table
      v-loading="loading"
      :data="classify_list"
      border
      row-key="id"
      :tree-props="{children: 'child'}">
      >
      <el-table-column label="分类名称" prop="name"></el-table-column>
      <el-table-column label="是否显示">
        <template slot-scope="item"> {{item.row.status | status}}</template>
</el-table-column>
<el-table-column label="排序">
    <template slot-scope="item">
    <el-button type="primary" @click="sortCate(item.row.id,1)">↑</el-button>
    <el-button type="primary" @click="sortCate(item.row.id,2)">↓</el-button>
  </template>
</el-table-column>
<el-table-column label="操作" fixed="right">
    <template slot-scope="item">
            <el-button type="primary" size="mini" @click="updateCate(item.row,true)">编辑</el-button>
            <el-button type="danger" size="mini" @click="delCate(item.row.id)">删除</el-button>
          </template>
</el-table-column>
</el-table>
<!-- 编辑 -->
<el-dialog title="添加/修改" :visible.sync="dialog" :close-on-click-modal=false width="60%">
    <el-form v-if="alter">
        <el-form-item label="父级分类">
            <el-select v-model="select_id" :disabled="is_disabled">
                <el-option v-for="item in classify" :label="item.name" :value="item.id"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="分类名称">
            <el-input v-model="classify_name"></el-input>
        </el-form-item>
        <el-form-item label="是否显示">
            <el-radio-group v-model="is_status">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="2">否</el-radio>
                </el-radio>
            </el-radio-group>
        </el-form-item>
    </el-form>
    <el-form v-if="!alter">
        <div v-if="!is_more">
            <el-form-item label="父级分类">
                <el-select v-model="form_data[0].parent_id" :disabled="is_disabled">
                    <el-option v-for="item in classify" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="分类名称">
                <el-input v-model="form_data[0].name"></el-input>
            </el-form-item>
            <el-form-item label="是否显示">
                <el-radio-group v-model="form_data[0].status">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="2">否</el-radio>
                    </el-radio>
                </el-radio-group>
            </el-form-item>
        </div>
        <div v-else="is_more">
            <div class="mt_10" v-for="item,index in form_data">
                <span class="ml_10">父级分类：</span>
                <el-select class="w_100" v-model="item.parent_id">
                    <el-option v-for="item in classify" :label="item.name" :value="item.id"></el-option>
                </el-select>
                <span class="ml_10">分类名称：</span>
                <el-input class="w_100" v-model="item.name" placeholder=""></el-input>
                <span class="ml_10">是否显示：</span>
                <el-select class="w_100" v-model="item.status">
                    <el-option label="是" value="1"></el-option>
                    <el-option label="否" value="2"></el-option>
                </el-select>
                <el-button class="ml_10" type="" @click="remove(index)">x</el-button>
            </div>
            <el-button class="mt_10" type="" @click="append">添加分类</el-button>
        </div>
    </el-form>
    <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="alter_classify">确定</el-button>
    </div>
</el-dialog>
</section>
</template>
<script>
    let common = JSON.parse(window.localStorage.getItem('common'))
    import * as api from '@/config/api'
    export default {
        name: 'goodPriceClassify',
        components: {},
        data() {
            return {
                loading: true,
                dialog: false,

                page: 1,
                pagesize: 10,
                total: 0,
                classify_list: [],

                classify: [{
                    id: '0',
                    name: '顶级分类'
                }],

                alter: false,
                id: '',
                select_id: '',
                classify_name: '',
                is_status: '',
                is_disabled: false,
                is_more: false,

                form_data: [{
                    name: '',
                    parent_id: '',
                    status: ''
                }],
                response: {}, // 详情
            }
        },
        watch: {},
        filters: {
            status(val) {
                switch (Number(val)) {
                    case 1:
                        return '是'
                        break;
                    case 2:
                        return '否'
                        break;
                    default:
                        return ''
                        break;
                }
            },

        },
        mounted() {
            this.getSellingClassifyList()
        },
        methods: {
            // 获取列表
            getSellingClassifyList() {
                api.getSellingClassifyList(null, res => {
                    this.classify_list = res.data.list
                    this.loading = false
                    this.classify = [...this.classify, ...res.data.list]
                })
            },
            // 页面切换
            sizeChange(val) {
                this.pageSize = val
                this.getSellingClassifyList()
            },
            currentChange(val) {
                this.page = val
                this.getSellingClassifyList()
            },
            // 添加或修改分类
            alter_classify() {
                if (this.id != '') {
                    api.updateCate({
                        id: this.id,
                        parent_id: this.select_id,
                        name: this.classify_name,
                        status: this.is_status
                    }, res => {
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        })
                        this.getSellingClassifyList()
                        this.dialog = false
                    })
                    return
                }
                api.addCate({
                    form_data: this.form_data,
                }, res => {
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    })
                    this.getSellingClassifyList()
                    this.dialog = false
                })
            },
            // 修改分类详情
            updateCate(data, alter) {
                this.alter = alter
                this.id = data.id
                this.select_id = data.parent_id
                this.classify_name = data.name
                this.is_status = Number(data.status)
                data.child.length != 0 ? this.is_disabled = true : this.is_disabled = false
                this.dialog = true
            },
            // 添加分类
            add() {
                this.id = ''
                this.alter = false
                this.is_more = false
                this.is_disabled = false
                this.form_data = [{
                    name: '',
                    parent_id: '',
                    status: ''
                }]
                this.dialog = true
            },
            more_add() {
                this.id = ''
                this.alter = false
                this.is_more = true
                this.form_data = [{
                    name: '',
                    parent_id: '',
                    status: ''
                }]
                this.dialog = true
            },
            append() {
                this.form_data.push({
                    name: '',
                    parent_id: '',
                    status: ''
                })
            },
            // 删除添加的分类
            remove(index) {
                if (index !== -1) {
                    this.form_data.splice(index, 1)
                }
            },
            // 删除分类
            delCate(id) {
                this.$confirm('分类删除后不可恢复，你还要继续吗？', '分类删除后不可恢复', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    api.delCate({
                        id: id
                    }, res => {
                        this.$message({
                            type: 'success',
                            message: res.msg
                        })
                        this.getSellingClassifyList()
                    })

                }).catch(() => {})
            },
            // 分类排序
            sortCate(id, sort) {
                api.sortCate({
                    id: id,
                    direction: sort
                }, res => {
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    })
                    this.getSellingClassifyList()
                })
            }
        }
    }
</script>
<style lang="scss">
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
    
    .w_100 {
        width: 100px;
    }
    
    .ml_10 {
        margin-left: 10px;
    }
    
    .mt_10 {
        margin-top: 10px;
    }
</style>